* {
    font-family: "Poppins";
}

body {
    font-family: "Poppins" !important;
}

@media (max-width: 480px) {
    .d-xs-none {
        display: none;
    }

    .ttm-menu-toggle {
        height: 78px;
    }
}

.our-project-section {
    background-color: rgb(0 74 173 / 57%);
}

.carousel .carousel-status {
    display: none;
}

.row:before {
    display: inherit !important;
}

.carousel .thumbs-wrapper {
    display: none !important;
}

.ttm-header-wrap {
    box-shadow: 0 0 10px #eee;
}

.carousel .slide img {
    object-fit: contain !important;
    background: black !important;
}

.carousel .slide:nth-child(2) img {
    background: #000 !important;
}

.featured-desc p {
    display: -webkit-box;
    -webkit-line-clamp: 8;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.vacancy-wrapper p {
    margin-bottom: 0 !important;
    padding: 0;
}

.vacancy-wrapper div {
    margin-bottom: 15px;
}

.featured-imagebox .featured-thumbnail img {
    height: 200px;
    width: 100%;
    object-fit: cover;
}

.custom-list {
    list-style: initial;
}

.custom-list li {
    padding: 10px 0;
}

section p {
    padding: 6px 0;
}

#site-header-menu #site-navigation .menu>ul>li>ul {
    border-top-color: red !important;
}

.featured-icon-box.left-icon.icon-align-top .featured-icon {
    display: none;
}

.faq-section .title {
    text-align: center;
    color: red;
}

.banner-wrapper .title {
    font-size: 30px !important;
    line-height: initial !important;
}

.ttm-icon img {
    margin-bottom: 50px;
}

.first-footer {
    display: none;
}

label {
    color: white;
    padding-bottom: 8px;
}

@media (max-width: 768px) {
    .carousel .slider-wrapper {
        height: 250px;
    }

    .first-footer {
        display: none;
    }
}